import React from 'react';
import { string, bool } from 'prop-types';
import loadable from '@loadable/component';

import {
  LOYALTY_SHARE_ROUTE,
  LOYALTY_ROUTE, CARD_ROUTE,
  ACTIVATE_ROUTE, DELETE_ROUTE,
  SUPPORT_PROBLEM,
} from 'constants/navigation';
import {
  SHARE, REWARD, MANAGE_CARD_NAV,
  ACTIVATE_NAV, DELETE_NAV, BUG,
} from 'constants/font-awesome';

import useCanShareReward from 'hooks/use-can-share-reward';

import useStyles from './styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/one'));
const Page = loadable(() => import('components/page'));
const ApplicationFooter = loadable(() => import('components/footer/application-footer'));
const ActionActiveView = loadable(() => import('views/loyalty-card/action-active-loyalty'));
const ActionCompleteView = loadable(() => import('views/loyalty-card/action-complete-loyalty'));

const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedLoyaltyCardViewPage = ({
  passId = '',
  title = '',
  passState = '',
  loading,
}) => {
  const styles = useStyles();
  const { canShare, canReward, canShareRewardLoading } = useCanShareReward(loading);

  const getNavs = () => {
    if (passState === 'active') {
      const navs = [
        { route: `${CARD_ROUTE}?passId=${passId}`, icon: MANAGE_CARD_NAV, message: 'Card' },
      ];
      if (canShare && canReward) {
        navs.push({ route: `${LOYALTY_SHARE_ROUTE}?passId=${passId}`, icon: SHARE, message: 'Share' });
        navs.push({ route: `${LOYALTY_ROUTE}?passId=${passId}`, icon: REWARD, message: 'Loyalty' });
        return navs;
      }
      if (canShare && !canReward) {
        navs.push({ route: `${LOYALTY_SHARE_ROUTE}?passId=${passId}`, icon: SHARE, message: 'Share' });
        navs.push({ route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' });
        return navs;
      }

      if (canReward && !canShare) {
        navs.push({ route: `${LOYALTY_ROUTE}?passId=${passId}`, icon: REWARD, message: 'Loyalty' });
        navs.push({ route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' });
        return navs;
      }

      navs.push({ route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' });
      return navs;
    }

    return [
      { route: `${CARD_ROUTE}?passId=${passId}`, icon: MANAGE_CARD_NAV, message: 'Card' },
      { route: `${ACTIVATE_ROUTE}?passId=${passId}`, icon: ACTIVATE_NAV, message: 'Activate' },
      { route: `${DELETE_ROUTE}?passId=${passId}`, icon: DELETE_NAV, message: 'Delete' },
    ];
  };

  return (
      <Page
        title={title}
        description="Work with your loyalty cards. Scan, Share, add Loyalty or Redeem.">
        <LazyBanner/>
        <Typography variant="h2" component="h1" className={styles.heading} align="center">
        {`Loyalty Card "${title}"`}
        </Typography>
        <Typography variant="h5" component="p" className={styles.headingMessage} align="center">
         {`${
           passState === 'active'
             ? 'This is an active loyalty card. Please choose an action from the menu.'
             : `This loyalty card has been completed but has not been activated. You can edit the loyalty card, or you can activate it.
              Please choose an action from the menu.`}`}
        </Typography>
          <div className={styles.content}>
            {passState === 'active' ? (
                <ActionActiveView
                  passId={passId}
                  title={title}
                  canShare={canShare}
                  canReward={canReward}
                  loading={canShareRewardLoading}/>
            ) : (
              <ActionCompleteView
                passId={passId}
                title={title}
                loading={canShareRewardLoading}
                />
            )}
          </div>
          <ApplicationFooter
            navItems={getNavs()}
            currentItem="Card"/>
      </Page>
  );
};

SuspendedLoyaltyCardViewPage.propTypes = {
  passId: string,
  title: string,
  passState: string,
  loading: bool.isRequired,
};

export default SuspendedLoyaltyCardViewPage;
